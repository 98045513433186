<template>
  <div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div
        class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-1 mt-1"
      >
        <div class="d-flex flex-column justify-content-center">
          <h4 class="mt-3"></h4>
        </div>
        <div class="d-flex align-content-center flex-wrap gap-3">
          <div class="d-flex gap-3">
            <b-button
              @click="onSubmit"
              type="submit"
              variant="primary"
              class="mr-2"
              >Enregistrer</b-button
            >
          </div>
          <b-button @click="resetForm()">Réinitialiser</b-button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-12">
          <div class="card mb-4">
            <div class="card-header">
              <h5 class="card-tile mb-0">Information utilisateur</h5>
            </div>
            <div class="card-body">
              <b-form @submit.stop.prevent="onSubmit">
                <div class="row mb-3">
                  <div class="col">
                    <h5>Identifiant</h5>
                    <b-form-input
                      id="example-input-1"
                      name="example-input-1"
                      v-model="$v.form.userIdentifiant.$model"
                      :state="validateState('userIdentifiant')"
                      aria-describedby="input-1-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback id="input-1-live-feedback"
                      >Champ obligatoire.</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col">
                    <h5>Role</h5>
                    <b-form-select
                      id="example-input-2"
                      name="example-input-2"
                      v-model="$v.form.userRole.$model"
                      :options="role"
                      :state="validateState('userRole')"
                      aria-describedby="input-2-live-feedback"
                    ></b-form-select>

                    <b-form-invalid-feedback id="input-2-live-feedback"
                      >Champ obligatoire.</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col">
                    <h5>Nom</h5>
                    <b-form-input
                      id="example-input-1"
                      name="example-input-1"
                      v-model="$v.form.userName.$model"
                      :state="validateState('userName')"
                      aria-describedby="input-1-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback id="input-1-live-feedback"
                      >Champ obligatoire.</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col">
                    <h5>Prenom</h5>
                    <b-form-input
                      id="example-input-2"
                      name="example-input-2"
                      v-model="$v.form.userPrenom.$model"
                      :state="validateState('userPrenom')"
                      aria-describedby="input-2-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback id="input-2-live-feedback"
                      >Champ obligatoire.</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col">
                    <H5>Email</H5>
                    <b-form-input
                      id="login-email"
                      v-model="$v.form.userEmail.$model"
                      :state="validateState('userEmail')"
                      name="login-email"
                      placeholder="john@example.com"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-2-live-feedback"
                      >Champ obligatoire et doit ètre
                      valide</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col">
                    <h5>Mot de passe</h5>
                    <b-form-input
                      id="example-input-2"
                      name="example-input-2"
                      v-model="$v.form.userPassword.$model"
                      :state="validateState('userPassword')"
                      aria-describedby="input-2-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback id="input-2-live-feedback"
                      >Le mot de passe doit comporter au minimum 6
                      caractere.</b-form-invalid-feedback
                    >
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import {
  BFormInvalidFeedback,
  BOverlay,
  BFormInput,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BFormSelect,
} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BFormSelect,
    BFormInvalidFeedback,
    BFormInput,
    BOverlay,
    BFormGroup,
    BForm,
    BInputGroup,
    BInputGroupAppend,
  },
  mixins: [validationMixin],
  data() {
    return {
      role: [
        { value: "admin", text: "Administrateur" },
        { value: "editor", text: "Editeur" },
        { value: "gestionner", text: "Gestionnaire de boutique" },
      ],
      form: {
        userIdentifiant: null,
        userRole: [],
        userName: null,
        userEmail: null,
        userPrenom: null,
        userPassword: null,
      },
      boxMsg: "",
      loading: false,
    };
  },
  validations: {
    form: {
      userRole: {
        required,
      },
      userIdentifiant: {
        required,
      },
      userPassword: {
        required,
        minLength: minLength(6),
      },
      userName: {
        required,
      },
      userPrenom: {
        required,
      },
      userEmail: {
        required,
        email,
      },
    },
  },
  methods: {
    //Permet de verifier si le champ est correctement saisi
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    //Permet la reinitialisation du formulaire
    resetForm() {
      this.form = {
        userIdentifiant: null,
        userRole: null,
        userEmail: null,
        userName: null,
        userPassword: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    //Permet au clique sur enregistrer de verifier si tout les champs sont correctement saisie, pour executer le processus de creation
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.showMsgBox();
    },
    //Permet la creation d'un utilisateur
    async createUser() {
      const createUserFunction = firebase
        .functions()
        .httpsCallable("create_user");
      try {
        const data = {
          fullName: this.form.userName + " " + this.form.userPrenom,
          username: this.form.userIdentifiant,
          email: this.form.userEmail,
          role: this.form.userRole,
          password: this.form.userPassword,
        };
        const response = await createUserFunction(data);
        if (response.data.success) {
          this.clearInput();
          this.makeToastMsg("info");
        } else {
          this.makeToastMsgError("info");
        }
      } catch (error) {
        alert("Error:", error);
      }
    },
    clearInput() {
      this.form.userName = "";
      this.form.userPrenom = "";
      this.form.userIdentifiant = "";
      this.form.userEmail = "";
      this.form.userRole = "";
      this.form.userPassword = "";
    },
    showMsgBox() {
      this.boxMsg = "";
      this.$bvModal
        .msgBoxConfirm(
          "Êtes-vous sûr de vouloir ajouter un nouvel utilisateur ?",
          {
            title: "Confirmation",
            size: "md",
            okVariant: "primary",
            okTitle: this.loading ? "Loading..." : "Confirmer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          this.boxMsg = value;
          if (value) {
            if (value) {
              this.loading = true;
              this.createUser().then(() => {
                this.loading = false;
              });
            }
          }
        });
    },
    makeToastMsg(variant = null) {
      this.$bvToast.toast("L'utilisateur a bien été ajouter", {
        title: `Utilisateur ${variant || "default"}`,
        variant,
        solid: true,
        href: this.permalink,
      });
    },
    makeToastMsgError(variant = null) {
      this.$bvToast.toast("L'utilisateur n'a pas pu être ajouté.", {
        title: `Utilisateur ${variant || "default"}`,
        variant,
        solid: true,
        href: this.permalink,
      });
    },
  },
};
</script>
<style scoped>
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #00cfe8 !important;
}
.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #00cfe8 !important;
}
body {
  padding: 1rem;
}
</style>
